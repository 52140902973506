import React from "react"
import { postTypesSlug } from "../../content/Global"
import { siteDetails } from "../../content/Global"
import ContentSection from "./ContentSection"
import Button from "../atoms/Button"
import ImageSlider from "./ImageSilder"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBath,
  faBed,
  faCar,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faRocket,
  faWheelchair,
} from "@fortawesome/free-solid-svg-icons"
import SectionTitle from "../atoms/SectionTitle"
import FontAwesomIconListItem from "../atoms/FontAwesomIconListItem"
import FaqsSection from "../common/FaqsSection"
import { Link } from "gatsby"

const InnerPropertyPage = ({ post }) => {
  return (
    <>
      <div
        className="pt-0 pb-5"
        style={{
          backgroundImage: `linear-gradient(to bottom, #FAFDEE 60%, transparent 40%)`,
        }}
      >
        <ImageSlider images={post?.images} />
      </div>

      <div className="cc-section--horizontal-space">
        <div className="mt-10 md:mt-10">
          <h1 className="text-[#25282C] text-[28px] md:text-[40px] leading-[1.4em] font-[600]">
            {post?.title}
          </h1>
          <h4 className="font-ubuntu flex flex-wrap gap-[10px] items-center text-[24px] leading-[1.2em] mt-4">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-[#1C9F85]" />
            <span>{post?.address}</span>
          </h4>
          <div className="flex flex-wrap gap-[25px] mt-4">
            {post?.beds > 0 ? (
              <FontAwesomIconListItem
                icon={faBed}
                text={post?.beds + ` Bedrooms`}
                textEC={`text-[#858585]`}
              />
            ) : (
              ""
            )}
            {post?.baths > 0 ? (
              <FontAwesomIconListItem
                icon={faBath}
                text={post?.baths + ` Bathrooms`}
                textEC={`text-[#858585]`}
              />
            ) : (
              ""
            )}
            {post?.cars > 0 ? (
              <FontAwesomIconListItem
                icon={faCar}
                text={post?.cars + ` Parking`}
                textEC={`text-[#858585]`}
              />
            ) : (
              ""
            )}
            {post?.accessibleFeature !== "" ? (
              <FontAwesomIconListItem
                icon={faWheelchair}
                text={post?.accessibleFeature}
                textEC={`text-[#858585]`}
              />
            ) : (
              ""
            )}
            {post?.vacancies !== "" ? (
              <FontAwesomIconListItem
                icon={faRocket}
                text={post?.vacancies}
                textEC={`text-[#1C5C4F]`}
              />
            ) : (
              ""
            )}
          </div>
          <Button
            to={postTypesSlug.propertiesSlug}
            href={`/contact-us/`}
            text={`Limited Vacancies Available - Enquire Now!`}
            ec={`primary-btn block max-w-[max-content] px-3 mt-6`}
          />
        </div>
        <div className="mt-10 md:mt-16 pt-0 md:pt-3 pb-5">
          <SectionTitle title={`Property <span>Information</span>`} />
          {post?.contentSections.map((section, index) => (
            <ContentSection
              key={index}
              title={section.title}
              titleEC={section.titleEC}
              description={section.description}
              list={section.list}
              listEC={section.listEC}
              listItemEC={section.listItemEC}
              image={section.image}
              imageEC={section.imageEC}
            />
          ))}
          <h4 className="text-[26px] font-[600] mb-4">Talk to us today.</h4>
          <div className="flex flex-col gap-[20px]">
            <Link
              to={`mailto:${siteDetails?.email}`}
              className="text-black hover:text-[#1c5c4f]"
            >
              <FontAwesomIconListItem
                icon={faEnvelope}
                text={siteDetails?.email}
              />
            </Link>
            <Link
              to={siteDetails?.phoneLink}
              className="text-black hover:text-[#1c5c4f]"
            >
              <FontAwesomIconListItem
                icon={faPhone}
                text={siteDetails?.phone}
              />
            </Link>
            <FontAwesomIconListItem
              icon={faMapMarkerAlt}
              text={post?.address}
            />
          </div>
        </div>
        <div className="mt-10 md:mt-12 pt-0 md:pt-3 pb-5">
          <SectionTitle
            title={`Property <span>Features</span>`}
            componentEC={`mb-8`}
          />
          <ContentSection
            list={post?.propertyFeatures?.list}
            listEC={post?.propertyFeatures?.listEC}
            listItemEC={post?.propertyFeatures?.listItemEC}
          />
        </div>
        <div className="mt-10 md:mt-6 pt-0 md:pt-3 pb-5">
          <SectionTitle
            title={post?.propertySDAInfo?.title}
            componentEC={`mb-8`}
          />
          {post?.propertySDAInfo?.description && (
            <p
              className="text-[16px] md:text-[18px] leading-[1.4em] mt-6 mb-8"
              dangerouslySetInnerHTML={{
                __html: post?.propertySDAInfo?.description,
              }}
            />
          )}
        </div>
        <div className="mt-10 md:mt-6 pt-0 md:pt-3 pb-5">
          <SectionTitle
            title={`View On <span>Map</span>`}
            componentEC={`mb-8`}
          />
          <iframe
            title="Propert Map"
            src={post?.propertyMapIframe}
            width="100%"
            height="300"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="entered exited lazyloaded"
          ></iframe>
        </div>

        <FaqsSection faqs={post?.faqs} componentEC={`!text-left`} />
      </div>
    </>
  )
}

export default InnerPropertyPage
